/*- Custom style -*/

// -- Put custom style under this point -- //
#header img {
    max-height: 200px;
    padding-left: 35px;
}

#header h1 {
    color: #C06D4F;
}

.drop-caps-section-type1 > p:first-child:first-letter {
    color: darken($main-color, 20%);
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
}

.drop-caps-section-type2 > p:first-child:first-letter {
    color: $main-color;
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
}

.drop-caps-section-type2::before {
    display: block;
    content: '';
    width: 3rem;
    height: 1px;
    margin: 23px auto;
    margin-top: 35px;

    margin-bottom: 35px;
    background-color: #d5d5d5;

}


.pullquote-right {
    margin-top: 0.5em;
    width:8em;
    padding:0.2em 0 0.2em 0;
    border-top:0.3em double darken($main-color,20%);
    border-bottom:0.3em double darken($main-color,20%);
    font-size:1.2em;
    line-height:1em;
    text-align:center;
    float:right;
    color: darken($main-color, 20%);
    p {
	margin-bottom: 0.1em;
    }
}

.pullquote-left {
    margin-top: 0.5em;
    margin-right: 0.5em;
    width:8em;
    padding:0.2em 0 0.2em 0;
    border-top:0.3em double darken($main-color,20%);
    border-bottom:0.3em double darken($main-color,20%);
    font-size:1.2em;
    line-height:1em;
    text-align:center;
    float:left;
    color: darken($main-color, 20%);
    p {
	margin-bottom: 0.1em;
    }
}

@media screen and (max-width: 600px) {
    .pullquote-left {
	display: none;
    }
    .pullquote-right {
	display: none;
    }
}

.image-center {
    .figure {
	p {
	    img {
		margin-bottom: 1em;
	    }
	}
    }
    .image-caption {
	border-top:0.1em solid darken($main-color,20%);
	p {
	    padding-top: 0.1em;
	    padding-bottom: 0.1em;
	}
    }
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    width:30em;
    padding:0.2em 0 0.2em 0;
    border-top:0.3em double darken($main-color,20%);
    border-bottom:0.3em double darken($main-color,20%);
    font-size:1em;
    line-height:0em;
    text-align:center;
    color: darken($main-color, 20%);
    p {
	margin-bottom: 0.1em;
    }
}

.pullimage-left {
    .figure {
	p {
	    img {
		margin-bottom: 1em;
	    }
	}
    }
    .image-caption {
	border-top:0.1em solid darken($main-color,20%);
	p {
	    padding-top: 0.1em;
	    padding-bottom: 0.1em;
	}
    }
    margin-top: 1em;
    margin-right: 0.5em;
    width:14em;
    padding:0.2em 0 0.2em 0;
    border-top:0.3em double darken($main-color,20%);
    border-bottom:0.3em double darken($main-color,20%);
    font-size:1em;
    line-height:0em;
    text-align:center;
    float:left;
    color: darken($main-color, 20%);
    p {
	margin-bottom: 0.1em;
    }
}

@media screen and (max-width: 600px) {
    .pullimage-left {
	float: none;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
    }
}
